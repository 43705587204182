import styled, { keyframes } from "styled-components";
import hireme from "../../image/logoImg/job-seeker.png";
import resumeIcon from "../../image/logoImg/resume.png";
import { useState } from "react";

const rotateToAnotherSide = keyframes`
  from {
    transform: rotate(0deg);
    margin-top: 2.5rem;
    border-bottom: 2px solid #000;
  }
  to {
    transform: rotate(90deg);
    margin-top: 0;
  }
`;

const rotateToVertical = keyframes`
  from {
    transform: rotate(-70deg);
    margin-top: 2.5rem;
  }
  to {
    transform: rotate(-90deg);
    margin-top: 2.5rem;
    padding-left: 20px;
  }
`;

const RotateText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);
  animation: ${({ $isExpanded }) =>
    $isExpanded ? rotateToAnotherSide : rotateToVertical} 0.5s forwards;
  white-space: nowrap;
  border-bottom: ${({ $isExpanded }) => ($isExpanded ? "2px solid #000" : "none")};
`;

export default function HireMeResume({ isSidebarExpanded }) {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <div
      className={`fixed top-0 left-0 md:mt-[25vh] transition-all ${
        isSidebarExpanded
          ? "md:ml-40 duration-200"
          : "md:ml-20 duration-[365ms]"
      }  mt-[28vh] ml-0 z-50 border-double border-2  border-black shadow-lg shadow-stone-500/50 flex box-border
      
       ${
         isExpanded
           ? "flex-row bg-[#f88a22c7] "
           : "flex-col h-[80px] w-[25px] bg-[#ff453f] "
       } items-center cursor-pointer`}
      onClick={() => setExpanded(!isExpanded)}
    >
      <div
        className={`text-white font-bold rounded items-start px-2 space-y-2 flex text-sm ${
          isExpanded ? "flex-col" : "hidden"
        }`}
      >
        <a
          href="mailto:anh.hotech@gmail.com"
          className="flex items-end space-x-2 pr-12 py-1 transition duration-300 ease-in-out rounded overflow-hidden hover:bg-red-600 hover:font-Noto"
        >
          <img src={hireme} alt="Email Me" className="w-9 h-9" />
          <span>Quick Email</span>
        </a>
        <a
          href="https://drive.google.com/file/d/1jH5K3nbgwXFOfE2AxjyL9AjHi5e3Bl3-/view?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-end space-x-2 py-1 pr-1 transition duration-300 ease-in-out rounded overflow-hidden hover:bg-blue-600 hover:font-Noto"
        >
          <img src={resumeIcon} alt="Download Resume" className="w-9 h-9" />
          <span>Download Resume</span>
        </a>
      </div>

      <RotateText $isExpanded={isExpanded} className="font-Noto">
        Hire me
      </RotateText>
    </div>
  );
}
