import React from "react";
import Experience from "./Experienece";
import Education from "./Education";
import TechStack from "./TechStack";


const Projects = () => (
  <div className="bg-[#fffaf0] p-5 rounded-lg shadow-md border-2 border-[#3e2723]">
    <h2 className="text-[#3e2723] text-lg font-[Courier] mb-4 tracking-wide">
      COMMUNITY INVOLVEMENT
    </h2>
    <ul className="list-disc list-inside text-[#3e2723] text-left">
      <li className="before:content-['] before:text-[#6b4e3d] before:mr-2">
        Volunteered for POWWOW as a setup position at Portland Community College
        from 2016-2019.
      </li>
      <li className="before:content-[ ] before:text-[#6b4e3d] before:mr-2">
        Participated in MLK services like picking up trash or microtrash at
        Oregon Coast hosted by Portland State University and Portland Community
        College. (2016-2019)
      </li>
      <li className="before:content-[ ] before:text-[#6b4e3d] before:mr-2">
        Volunteered for Vietnamese Community of Oregon in the Grand Floral
        Parade during Pdx Rose Festival 2016.
      </li>
      <li className="before:content-[ ] before:text-[#6b4e3d] before:mr-2">
        Being an active volunteer member within the HandsOn Greater Portland
        Network and the PCC community since 2015.
      </li>
    </ul>
  </div>
);
const TechStackAndExperience = () => {
  return (
    <div
      className="p-3 md:p-10 m-2 flex flex-wrap justify-between md:justify-start gap-4 overflow-hidden max-h-screen 
                  overflow-y-auto md:pb-10 pb-[18vh]"
    >
      {/* Each box as a flex item that can grow based on content */}
      <div className="flex flex-col flex-grow md:min-w-[300px] max-h-[600px] bg-white p-4 shadow rounded-lg">
        <Education />
      </div>
      <div className="flex flex-col flex-grow max-h-[600px] bg-white p-4 shadow rounded-lg">
        <Experience />
      </div>
      <div className="flex flex-col flex-grow max-h-[600px] bg-white p-4 shadow rounded-lg overflow-y-auto ">
        <TechStack />
      </div>
      <div className="flex flex-col flex-grow bg-white p-4 shadow rounded-lg overflow-y-auto">
        <Projects />
      </div>

    </div>
  );
};


export default TechStackAndExperience;
