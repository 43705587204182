import React, { useState } from "react";
import Data_TechStack from "./Data_TechStack";
const TechStack = () => {
  const [selectedCategory, setSelectedCategory] = useState("Full Stack");

  const subCategory_fullStack = [
          "Frontend",
          "Backend",
          "Database",
          "Frameworks & Libraries",
          "Tools",
  ]
  
  const totalTechStack_categories = [
    "Full Stack",
    "IT Support",
    "Editing/Design",
  ];
  
  // Function to generate button classes based on whether it's active
  const buttonClass = (category) => `
  m-1 p-2 rounded-lg
  transition-all duration-300 ease-in-out
  font-[Garamond] tracking-wide
  ${
    selectedCategory === category
      ? "bg-[#d6c8af] text-[#3e2723] shadow-xl border-2 border-[#3e2723] font-bold"
      : "bg-[#e4d9c5] text-[#6b4e3d] hover:bg-[#d6c8af] hover:text-[#3e2723] opacity-75 hover:opacity-100"
  }
`;

  return (
    <div className="bg-[#fffaf0] border-2 border-[#3e2723]  rounded-lg shadow-md overflow-y-auto">
      <div className=" sticky top-[-5px] bg-[#fffaf0] shadow-sm ">
        <h2
          className="text-[#3e2723] text-lg font-['Courier_New', Courier, monospace] 
  mb-4 pt-4 pb-2 bg-[#f0e6d6] shadow-lg rounded-sm tracking-wide 
  text-shadow-[1px_1px_2px_rgba(0,0,0,0.5)]"
        >
          Technologies
        </h2>

        <div className="mb-2 pb-2">
          {totalTechStack_categories.map((category) => (
            <button
              key={category}
              className={buttonClass(category)}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      {selectedCategory === "Full Stack" ? (
        subCategory_fullStack.map((subcategory) => (
          <div key={subcategory} className="px-1 pb-4">
            <h3 className="font-bold ">{subcategory}</h3>
            <div className="flex flex-wrap justify-start gap-3 py-4 pl-8">
              {Data_TechStack.sort((a, b) => a.name.localeCompare(b.name))
                .filter(
                  (tech) =>
                    tech.category === "Full Stack" &&
                    tech.subcategory === subcategory
                )
                .map(renderTech)}
            </div>
          </div>
        ))
      ) : (
        <div className="flex flex-wrap justify-start gap-3 p-6">
          {Data_TechStack.sort((a, b) => a.name.localeCompare(b.name))
            .filter((tech) => tech.category === selectedCategory)
            .map(renderTech)}
        </div>
      )}
    </div>
  );
};

function renderTech(tech) {
  return (
    <div
      key={tech.name}
      className="flex items-center bg-[#e4d9c5] p-2 rounded-lg shadow-md"
    >
      <span className="text-lg mr-2 text-[#a17a5a]">{tech.icon}</span>
      {tech.name}
    </div>
  );
}

export default TechStack;
