import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "../../CSS/Intro.css";

const images = [];

for (let i = 1; i <= 4; i++) {
  const module = await import(`../../image/introImage/ah${i}.webp`);
  images.push(module.default);
}

export default function AvaPhotoSlides({
  handleCenterModeChange,
  centerModeIndex,
  setCenterModeIndex,
  isMobileView,
  expandedSlide,
}) {
  const [imageIndex, setImageIndex] = useState(0);

  const sliderRef = useRef();

  const settings_mobile = {
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    centerPadding: '0px',
    nextArrow: <div style={{ display: "none" }}></div>,
    prevArrow: <div style={{ display: "none" }}></div>,
    beforeChange: (current, next) => {
      setImageIndex(next);
      setCenterModeIndex(next);
      // Notify the parent component about the change
      // reflect the content to the index of the photo
      handleCenterModeChange(next);
    },
  };
  const handleDotClick = (index) => {
    if (index !== centerModeIndex) {
      setCenterModeIndex(index);
    }
  };

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(centerModeIndex);
    }
  }, [centerModeIndex]);

  return (
    <div className="flex md:flex-col justify-center">
      <div
        className={`${expandedSlide ? "PhotoProfile" : "PhotoProfile"} mx-auto`}
      >
        <Slider ref={sliderRef} {...settings_mobile}>
          {images.map((img, idx) => (
            <div
              key={idx}
              className={
                idx === centerModeIndex ? "slide activeSlide" : "slide"
              }
            >
              <div className="slide-inner">
                <img src={img} alt={`img-${idx}`} />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <Dots
        handleDotClick={handleDotClick}
        totalImages={images.length}
        centerModeIndex={centerModeIndex}
      />
    </div>
  );
}

const Dots = ({ handleDotClick, totalImages, centerModeIndex }) => {
  return (
    <div className="flex md:flex-row flex-col md:mt-4 mt-20 pl-3 justify-center gap-3 ">
      {Array.from({ length: totalImages }).map((_, idx) => (
        <button
          key={idx}
          onClick={() => handleDotClick(idx)}
          className={`h-3 w-3 rounded-full transition-colors ${
            idx === centerModeIndex ? "bg-neutral-50" : "bg-neutral-500"
          }`}
        />
      ))}
    </div>
  );
};
