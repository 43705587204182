import React, { useState } from "react";
import { Route, Routes, NavLink } from "react-router-dom";
import Intro from "../HomePage/Intro.jsx";
import Info from "../InfoPage/Info.jsx";
import InputMessage from "../MsgTab/AnonymousMess";
import logo_ah from "../../image/logo_AH2.webp";
import dataCode from "../../image/asserts/data-code.gif"
import TimeZoneAPI from "../../components/timeZoneAPI/TimeZoneAPI.jsx"
import ArrowBtn from "../../image/asserts/arrow.gif"
import ProjectPage from "../ProjectPage/ProjectMain.jsx";
import HireMeResume from "./HireMeResume.jsx";

import "../../CSS/global.css"
// import LandingPage from '../LandingPage/LandingPage';


// import the path of the logo image
function importAll(r) {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("../../image/asserts", false, /\.(png|jpe?g|svg|gif)$/)
);
// source for menu items to display icons
const menuItems = [
  { title: "Home", src: "Home_logo.png", path: "/", color: "#06b6d4" },
  { title: "Info", src: "info.png", path: "/Info", color: "#f59e0b" },
  {
    title: "Project",
    src: "Project_logo.gif",
    path: "/Project",
    color: "#e11d48",
  },
  {
    title: "Contact",
    src: "Message_logo.png",
    path: "/Contact",
    color: "#16a34a",
  },
];

// menu items for the sidebar
function MenuItem({ item, isOpen }) {
  
  return (
    <NavLink
      to={item.path}
      className={({ isActive }) =>
        `flex p-2 gap-x-4 cursor-pointer hover:text-2xl font-Francois blue-shadow
         white-shadow text-sm text-gray-300 
         ${!isOpen ? "flex-col items-center" : ""} 
         
         ${
           isActive
             ? `text-[${item.color}]  border-l-4 border-[${item.color}] `
             : " "
        }`
        
      }
    >
      <div className="flex items-center gap-x-3">
        <img
          src={images[`${item.src}`]}
          alt={`${item.title} Icon`}
          className={`cursor-pointer transform duration-500 ${
            !isOpen ? "w-7 h-7" : "w-10 h-10 ml-1"
          }`}
        />

        <span
          className={`origin-left duration-200 hover:text-[${item.color}]
          ${!isOpen && "hidden"}`}
        >
          {item.title}
        </span>
      </div>
    </NavLink>
  );
}


// Bottom bar when reducing the screen size to mobile view.
function BottomBar({ menuItems }) {
  return (
    <div className="z-50 fixed bottom-0 bg-[#292524] font-Francois w-full h-16 flex justify-around items-center md:hidden">
      {menuItems.map((item, index) => (
        <NavLink
          key={index}
          to={item.path}
          className={({ isActive }) =>
            `flex flex-col items-center justify-center  w-full h-full text-white ${
              isActive
                ? `opacity-100 border-b-2 border-[${item.color}] text-[${item.color}] white-shadow`
                : "opacity-50 blue-shadow "
            }`
          }
        >
          <img
            src={images[item.src]}
            alt={`${item.title} Icon`}
            className="w-6 h-6 cursor-pointer"
          />
          <span className="text-xs">{item.title}</span>
        </NavLink>
      ))}
    </div>
  );
}


// sideBar for desktop view.
function SideBar() {
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isTouchLogo, setTouchLogo] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="flex relative">
      {/* Sidebar for larger screens */}
      <div
        className={`z-50 bg-[#292524] h-screen fixed md:block hidden
              ${isSidebarExpanded ? "w-40 duration-200" : "w-20 duration-500"}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {/* logo */}
        <div className={`flex items-center white-shadow`}>
          <img
            src={logo_ah}
            className={`cursor-pointer transition-all ease-in-out ${
              isSidebarExpanded ? "scale-125 pt-2 pl-3 pr-3 mb-4" : ""
            } ${isTouchLogo ? " duration-300  rotate-infinite" : ""}`}
            alt="Logo"
            onMouseEnter={() => setTouchLogo(true)}
            onMouseLeave={() => setTouchLogo(false)}
          />
        </div>

        {/* navbar item list */}
        <ul className=" items-center ">
          {menuItems.map((item, index) => (
            <MenuItem key={index} item={item} isOpen={isSidebarExpanded} />
          ))}
        </ul>

        <div className={`absolute bottom-0 gap-1`}>
          <img src={dataCode} alt="dataCode" />

          <div
            className="p-1 opacity-30 cursor-pointer hover:opacity-70 border-y-4"
            onClick={() => setSidebarExpanded(!isSidebarExpanded)}
          >
            <img
              src={ArrowBtn}
              alt="ArrowBtn"
              className={`w-[30px] h-5
            ${isSidebarExpanded ? "rotate-180" : " arrow-animation"}`}
            />
          </div>
        </div>

        {isHovered ? (
          <div
            className="opacity-75 hover:opacity-100"
            onClick={() => setSidebarExpanded(!isSidebarExpanded)}
          >
            {/* <WeatherAPI isSidebarExpanded={isSidebarExpanded} /> */}
            {/* timeZoneAPIT and Weather API */}
            <TimeZoneAPI
              timezone="America/Los_Angeles"
              isSidebarExpanded={isSidebarExpanded}
            />
          </div>
        ) : null}
      </div>

      {/* Bottom bar for smaller screens */}
      <BottomBar menuItems={menuItems} className="md:hidden" />
      <HireMeResume isSidebarExpanded={isSidebarExpanded} />

      {/* ================= Main content ===================== */}
      <div
        // if md for the sidebar expanded is a trick.
        // that helps to expand the whole screen for the mobile size.
        className={`flex-1 duration-300  ${
          isSidebarExpanded
            ? "md:ms-40 w-screen h-screen"
            : "md:ms-20 w-screen h-screen"
        }`}
      >
        <Routes>
          <Route path="/" element={<Intro />} />
          <Route path="/Info" element={<Info />} />
          <Route path="/Contact" element={<InputMessage />} />
          <Route
            path="/Project"
            element={<ProjectPage isSidebarExpanded={isSidebarExpanded} />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default SideBar;
