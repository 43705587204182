import React, { useEffect, useState } from "react";
import AOS from "aos";

import slideContent from "../HomePage/slideContentData";
import AvaPhotoSlides from "../HomePage/AvaPhotoSlides";
import "../../CSS/Intro.css";
import DisplaySlideContent from "./DisplaySlideContent";
import ContentSlideThree from "./ContentSlideThree";
AOS.init();

function Intro(props) {
  const [centerModeIndex, setCenterModeIndex] = useState(0);
  const [isMobileView, setMobileView] = useState(window.innerWidth < 640);
  const [expanded, setExpanded] = useState(false);
  // Array of tag names
  const tagNames = ["#Who", "#FunFact", "#FindMe", "#ExtraMe"];

  const handleCenterModeChange = (index) => {
    setCenterModeIndex(index);
  };

  // Debounce function to limit the rate of window resize events
  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      setMobileView(window.innerWidth < 640);
    }, 200);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    // Use overflow-y-auto to allow vertical scrolling within the div when the content exceeds its height.
    <div className="bottom-0 top-0 m-0 p-0 h-full w-full overflow-y-auto pb-16 md:pb-0">
      {/* Container */}
      <div className="flex flex-col sm:flex-row h-full w-full overflow-hidden md:p-8">
        <div
          className={`custom-box flex flex-col sm:flex-row h-full w-full overflow-hidden border-4 border-white md:p-8 mt-0`}
          style={{ marginTop: "3px" }}
        >
          {/* Left Side */}
          <div
            className={`flex flex-col items-center justify-center overflow-hidden z-30  h-full w-full pl-4 ${
              isMobileView ? "animate-content" : ""
            }`}
          >
            <div className="pt-[24vw] md:pt-0 items-center justify-center">
              {/* Content */}

              {isMobileView && (
                <div className="custom-tittle font-Rubik ">
                  {centerModeIndex === 0 && <h2>Who's This?</h2>}
                  {centerModeIndex === 1 && <h2>Know Me!</h2>}
                  {centerModeIndex === 2 && <h2>Find Me! </h2>}
                  {centerModeIndex === 3 && <h2>Extra Me!</h2>}
                </div>
              )}
              <AvaPhotoSlides
                expandedSlide={expanded}
                handleCenterModeChange={handleCenterModeChange}
                isMobileView={isMobileView}
                centerModeIndex={centerModeIndex}
                setCenterModeIndex={setCenterModeIndex}
              />
            </div>
            <p className="md:mt-2 ml-[-15px] md:ml-8 font-bold font-Dancing text-xl md:text-3xl">
              Portland State University
            </p>
          </div>

          {/* Right Side */}
          <div className="flex flex-col overflow-y-auto relative z-10 h-full w-full ">
            <div
              className="px-4 md:pr-8 lg:pr-20 pb-8 m-auto"
              data-aos="flip-right"
            >
              {/* Content */}
              {centerModeIndex === 0 && (
                <DisplaySlideContent
                  content={slideContent.slideOne}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              )}
              {centerModeIndex === 1 && (
                <DisplaySlideContent
                  content={slideContent.slideTwo}
                  expanded={expanded}
                  setExpanded={setExpanded}
                />
              )}
              {centerModeIndex === 2 && <ContentSlideThree />}

              {/* Tag Boxes */}
              <div className="mb-0 mt-4 flex flex-wrap justify-center font-Dancing text-lg">
                {tagNames.map((tag, index) => (
                  <div className="tag-box" key={index}>
                    <div
                      className={`tag ${
                        centerModeIndex === index ? "active" : ""
                      }`}
                      onClick={() => setCenterModeIndex(index)}
                    >
                      {tag}
                    </div>
                  </div>
                ))}
              </div>
              {/* ============Tag Boxes */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



// function Content_four() {
//   return (
//     <>
//       <h1 className="text-3xl font-bold text-blue-500">Anh Ho</h1>
//       <div className="mt-4 text-blue-700 text-left">
//         <h2> COntent Four</h2>
//       </div>
//       <div className="mt-4 flex items-center justify-center space-x-2">
//         <a
//           href="https://www.linkedin.com/in/anh-hotech/"
//           target="_blank"
//           rel="noopener noreferrer"
//           className="text-blue-500 hover:text-blue-700 mb-2 sm:mb-0 sm:mr-2"
//         >
//           <img src={linkedinLogo} alt="LinkedIn" className="w-6 h-6" />
//         </a>

//         <a
//           href="mailto:anh.hotech@gmail.com"
//           className="text-blue-500 hover:text-blue-700 mb-2 sm:mb-0 sm:mr-2"
//         >
//           <img src={gmailLogo} alt="Gmail" className="w-6 h-6" />
//         </a>

//         <a href="youtube-url" className="text-blue-500 hover:text-blue-700">
//           <img
//             src={youtubeLogo}
//             alt="YouTube"
//             className="w-6 h-6 cursor-pointer"
//           />
//         </a>
//       </div>
//     </>
//   );
// }

export default Intro;
