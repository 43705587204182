import React, { useState, useEffect, useRef } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { MdWork } from "react-icons/md";
import styled from "styled-components";
import collapseIcon from "../../image/asserts/collapse.png";
import expandIcon from "../../image/asserts/expand.png";

const StyledVerticalTimelineElement = styled(VerticalTimelineElement)`
  .vertical-timeline-element-content {
    background: #f4f4f2; // A soft vintage paper-like background
    border: 2px solid #c6a679; // Aged paper or leather border color
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
  }
  .vertical-timeline-element-icon {
    background: #fc6a4a; // A strong, standout color for icons
    box-shadow: 0 0 0 4px #d8d2c4 inset; // Inset shadow for a pressed effect
  }
  .vertical-timeline-element-date {
    color: #555; // Dimmed color for a vintage feel
    font-family: "Courier New", Courier, monospace; // Vintage typewriter font
  }
  .vertical-timeline::before {
    background: #c6a679; // Matching the border of the content
  }
  .vertical-timeline-element-date {
    // size of the date
    max-width: 250px;
    margin-right: 10px;
  }
`;

function Experience() {
  const containerRef = useRef(null);
  const [isCompact, setIsCompact] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        setIsCompact(containerWidth < 800);
      }
    };
    console.log("hello: ", isCompact);
    handleResize(); // Call once on mount to set initial state
    window.addEventListener("resize", handleResize); // Adjust on window resize
    return () => window.removeEventListener("resize", handleResize); // Cleanup listener on unmount
  }, []);

  const experiences = [
    {
      id: "001",
      title: "STEM Curriculum Navigator — PSU’s Capstone Project",
      location: "Portland, OR",
      date: "Sep 2023 - Mar 2024",
      description: "Full Stack Developer",
      icon: <MdWork />,
      points: [
        "Drive impactful solutions as a Full Stack Developer, leading the development of an interactive website for an Agile team to help users filter, search, favorite the programs effectively.",
        "Employed a robust tech stack (JavaScript, ReactJS, Node.js, Auth0, Cookies, Bootstrap, MySQL), enhancing secure authentication, optimizing data handling, and implementing dynamic search functionality.",
        "Contributed to team efficiency, achieving milestones and proactively addressing challenges for a cohesive and successful project.",
      ],
    },
    {
      id: "002",
      title: "Murray Business Center, Providence St. Joseph Health",
      location: "Beaverton, OR",
      date: "July, 2020 - Jun, 2021",
      description: "IT Support Specialist, AskIT Service Desk",
      icon: <MdWork />,
      points: [
        "Provided streamlined remote technical support to healthcare professionals, achieving call times under 7 minutes during the COVID-19 pandemic.",
        "Proficiently utilized RDP, SCCM, and Citrix for remote support, managing AD, VPN, and Azure. Supported medical software like Epic and Meditech.",
        "Efficiently resolved complex technical issues using ServiceNow (AWS) for swift ticket-based solutions.",
      ],
    },
    {
      id: "003",
      title:
        "Rock Creek’s Student Computing Center and Library, Portland Community College",
      location: "Portland, OR",
      date: "January, 2017 - March, 2020",
      description: "Computer Lab Assistant, Technical Support",
      icon: <MdWork />,
      points: [
        "Supported end users with IT access and software/devices, also tutoring in programming languages like C/C++, Java, Python, etc.",
        "Monitored lab resources and reported non-functional workstations and peripherals. Managed guests’ access.",
      ],
    },
  ];


  return (
    <div
      ref={containerRef}
      className="bg-[#f0e6d6] border-2 border-[#3e2723]  rounded-lg shadow-md overflow-y-auto"
    >
      <h2
        className="text-[#3e2723] text-lg font-['Courier_New', Courier, monospace]  
          mb-4 sticky top-[-5px] pt-4 bg-[#f0e6d6] z-20 pb-2
         shadow-lg shadow-stone-500/40 rounded-sm
         "
      >
        Experience
      </h2>
      <VerticalTimeline layout={isCompact ? "1-column" : "2-columns"}>
        {experiences.map((experience) => (
          <ExperienceElement key={experience.id} experience={experience} />
        ))}
      </VerticalTimeline>
    </div>
  );
}
const ExperienceElement = ({ experience }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDetails = () => setIsOpen(!isOpen);

  return (
    <StyledVerticalTimelineElement
      date={experience.date}
      icon={experience.icon}
      contentArrowStyle={{
        borderRight: `10px solid ${isOpen ? "#fc6a4a" : "#c6a679"}`,
      }}
    >
      <h3
        className="vertical-timeline-element-title cursor-pointer font-Noto font-bold"
        onClick={toggleDetails}
      >
        {experience.title}
      </h3>
      <h5
        className="vertical-timeline-element-subtitle"
        style={{ color: "#7a6240" }}
      >
        {experience.location}
      </h5>
      <p style={{ fontStyle: "italic", color: "#4a4033" }}>
        {experience.description}
      </p>
      <img
        src={isOpen ? collapseIcon : expandIcon}
        alt={isOpen ? "Collapse" : "Expand"}
        className="cursor-pointer mt-2 mx-auto w-8 h-8 z-2 hover:scale-105 animate-bounce"
        onClick={toggleDetails}
      />

      {isOpen && (
        <div className="relative">
          <div className="border-l-4 h-3 mx-auto border-[#fc6a4a] my-[-9px] absolute left-1/2 transform -translate-x-1/2 z-10" />
          <ul
            className="mt-2 overflow-hidden transition-max-height duration-300 ease-in-out border-t-2 
            border-[#fc6a4a] rounded-md shadow-lg  text-[#3e2723] text-left"
            style={{
              maxHeight: isOpen ? "500px" : "0",
              background: "#fdfbf7",
              paddingLeft: 0,
              marginLeft: 0,
            }}
          >
            {experience.points.map((point, index) => (
              <li
                key={index}
                className="text-sm list-disc list-inside p-2"
                style={{ marginLeft: 0 }}
              >
                {point}
              </li>
            ))}
          </ul>
        </div>
      )}
    </StyledVerticalTimelineElement>
  );
};


export default Experience;
