import React from 'react';
import SideBar from './a_layout/1NavBar/SideBar';
import RotatingBackground from './components/utils/RotatingBackground';
import '../src/CSS/App.css';
import PreLoader from './components/UI/Preloader';
const App = () => {
  return (
    <div className="App">
      {/* background for the whole site */}
      <PreLoader/>
      <RotatingBackground duration={160}>
        <SideBar />
      </RotatingBackground>
      
    </div>
  );
};
export default App;
