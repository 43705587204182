import gsap from "gsap";

const tl = gsap.timeline();

export const preLoadAnimation = () => {
  tl.to("body", {
    duration: 0.1,
    css: { overflowY: "hidden" },
    ease: "power4.inOut",
  })

    .to(".texts-container", {
      duration: 0.5,
      opacity: 1,
      ease: "power4.easeOut",
    })
    .from(".texts-container span", {
      duration: 1,
      delay: 1,
      y: 50,
      skewY: 5,
      stagger: 0.3,
      ease: "power4.out",
    })
    .to(".texts-container span", {
      duration: 1,
      y: -50,
      skewY: -5,
      stagger: 0.3,
      opacity: 0,
      ease: "power4.out",
    })
    .to(
      ".preloader",
      {
        duration: 0.5,
        height: "0vh",
        ease: "power4.inOut",
      },
      "-=.75"
    )

    // Hide the preloader after the text disappears
    .to(".preloader", {
      duration: 0.5,
      opacity: 0,
      ease: "power4.inOut",
      onComplete: () => {
        document.querySelector(".preloader").style.display = "none";
      },
    });
};
