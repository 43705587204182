import React from "react";

const Pagination = ({ currentPage, pageCount, goToPreviousPage, goToNextPage, setCurrentPage }) => {
    return (
        <div className="max-w-screen-lg mx-auto flex items-center">
            <button
                onClick={goToPreviousPage}
                className="mx-2 px-3 py-1 text-sm font-medium text-white bg-transparent border border-cyan-500 hover:border-cyan-700 rounded-md shadow-md disabled:opacity-50"
                disabled={currentPage === 1}
            >
                Prev
            </button>
            {[...Array(pageCount).keys()].map((number) => (
                <button
                    key={number}
                    onClick={() => setCurrentPage(number + 1)}
                    className={`mx-1 px-3 py-1 text-sm font-semibold leading-5 transition-colors duration-150 border ${currentPage === number + 1
                            ? "bg-transparent bg-white text-cyan-950 hover:text-gray-800 border-2 border-cyan-600"
                            : "text-white border-cyan-500 hover:border-cyan-700 hover:border-3 hover:text-base"
                        } shadow-md rounded-md`}
                >
                    {number + 1}
                </button>
            ))}
            <button
                onClick={goToNextPage}
                className="mx-2 px-3 py-1 text-sm font-medium text-white bg-transparent border border-cyan-500 hover:border-cyan-700 rounded-md shadow-md disabled:opacity-50"
                disabled={currentPage === pageCount}
            >
                Next
            </button>
        </div>
    );
};

export default Pagination;
