import React from "react";
import ProjectCard from "./ProjectCard";

const ProjectsList = ({ projects, isSidebarExpanded }) => {
  return (
    <div
      className="grid grid-cols-1 md:grid-cols-2 gap-4 py-4 px-3"
      data-aos="zoom-in-left"
    >
      {projects.map((project, index) =>
        project.noResult ? (
          <div
            key={index}
            className="border border-gray-300 p-4 m-4 rounded-md"
          >
            <img
              src="https://portal.mixcarehealth.com/_nuxt/img/no_result.7118b4d.png"
              alt="No result found"
              className="text-white rounded-md floating shadow-md shadow-yellow-700"
            />
            <p className="text-white pt-3 animate-pulse">No result found!</p>
          </div>
        ) : (
          // return the result after filtering by input
          <div key={index} className="floating">
            <ProjectCard  {...project} isSidebarExpanded={isSidebarExpanded} />
          </div>
        )
      )}
    </div>
  );
};

export default ProjectsList;
