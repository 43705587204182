import React, { useState } from "react";
import styled from "styled-components";

// Mapping of category keys to their display names
const categoryMappings = {
    title: "Project Title",
    overview: "Project Overview",
    role: "Role",
    techStack: "Tech Stack",
    responsibilities: "Responsibilities",
    // achievements: "Achievements",
    // collaboration: "Team Collaboration",
    // duration: "Project Duration"
};

// Get the list of category names
const categoryNames = Object.keys(categoryMappings);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .searching-bar-select,
  .searching-bar-input {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0.2rem;
    margin-right: 0.5rem;
    height: 2rem;
  }

  .searching-bar-select {
    flex: 1; 
    max-width: 100%;
    width: 100%;
  }

  .searching-bar-input {
    flex: 1;
    max-width: 100%;
    width: 100%;
  }
  }
`;
// Functional component for the SearchingBar
const SearchingBar = ({ onSearch, searchInput, setSearchInput }) => {
  // State for the selected category and search input
 const [selectedCategory, setSelectedCategory] = useState("");

  // Event handler for when the category selection changes
  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    // Perform search with the updated category and current input
    performSearch(e.target.value, searchInput);
  };

  // Event handler for when the search input changes
  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
    // Perform search with the updated input and current category
    performSearch(selectedCategory, e.target.value);
  };

  // Function to perform the search
  const performSearch = (category, input) => {
    // Call the provided onSearch function with the category and input
    onSearch(category, input);
  };

  // Render the component
  return (
    <Container>
      {/* Select element for choosing category */}
      <select
        value={selectedCategory}
        onChange={handleCategoryChange}
        className="searching-bar-select"
      >
        {/* Option for all categories */}
        <option value="">All Categories</option>
        {/* Map over category names and create options */}
        {categoryNames.map((category) => (
          <option key={category} value={category}>
            {categoryMappings[category]}
          </option>
        ))}
      </select>
      {/* Input element for search */}
      <input
        type="text"
        value={searchInput}
        onChange={handleSearchInputChange}
        placeholder="Pick and Search"
        className="searching-bar-input"
      />
    </Container>
  );
};

export default SearchingBar;
