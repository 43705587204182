import React from "react";

const getButtonClassName = (currentType, type) => `
  ${
    currentType === type
      ? " bg-[#d2b48c] text-[#3e2723] border-2 border-[#8b4513] shadow-md scale-110"
      : "bg-[#f5f5dc] text-[#3e2723] border-2 border-[#452209] shadow-md bg-opacity-50"
  } px-4 py-2 rounded-md transition duration-300 hover:scale-105 hover:shadow-lg
`;

const ProjectTabs = ({ currentProjectType, setCurrentProjectType }) => (
  <div className="flex justify-center space-x-4 pt-6 pb-4">
    <button className={getButtonClassName(currentProjectType, "present")} onClick={() => setCurrentProjectType("present")}>
      Present Projects
    </button>
    <button className={getButtonClassName(currentProjectType, "old")} onClick={() => setCurrentProjectType("old")}>
      Previous Projects
    </button>
  </div>
);



export default ProjectTabs;
