import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const blink = keyframes`
  50% {
    opacity: 0;
  }
`;

const Cursor = styled.span`
  animation: ${blink} 1s step-end infinite;
`;

const TypeWriter = ({ sourceText, delay }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTypingDone, setIsTypingDone] = useState(false);

  useEffect(() => {
    if (currentIndex < sourceText.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + sourceText[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    } else {
      setIsTypingDone(true);
    }
  }, [currentIndex, delay, sourceText]);

  return (
    <span>
      {currentText}
      {!isTypingDone && <Cursor>...</Cursor>}
    </span>
  );
};

export default TypeWriter;
