import React from "react";
import { Link } from "react-router-dom";

// Importing image icons from your assets
import linkedinLogo from "../../image/logoImg/linkedin.png";
import gmailLogo from "../../image/logoImg/gmail.png";
import quickMessage from "../../image/logoImg/quickMessage.png";
import github from "../../image/logoImg/github.png";
import leetcode from "../../image/logoImg/leetcode.png";

export default function ContentSlideThree() {
  return (
    <div
      className=" mx-auto bg-amber-50 border border-amber-200 rounded-lg shadow-inner 
      p-10 mt-6 hover:shadow-lg transition-shadow duration-300 space-y-2"
      data-aos="fade-left"
      data-aos-duration="400"
    >
      <h1 className="text-3xl font-bold text-amber-900">Anh Ho</h1>
      <p className="text-amber-700">People can find me at:</p>
      <div className="mt-6 flex items-center justify-center space-x-4 animate-pulse ">
        <a
          href="https://www.linkedin.com/in/anh-hotech/"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer hover:brightness-75"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="900"
        >
          <img
            src={linkedinLogo}
            alt="LinkedIn"
            className="w-12 h-12 transition duration-300"
          />
        </a>
        <a
          href="mailto:anh.hotech@gmail.com"
          className="cursor-pointer hover:brightness-75"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1100"
        >
          <img
            src={gmailLogo}
            alt="Gmail"
            className="w-12 h-12 transition duration-300"
          />
        </a>
        <a
          href="https://github.com/anhoop89"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer hover:brightness-75"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1200"
        >
          <img
            src={github}
            alt="GitHub"
            className="w-12 h-12 transition duration-300"
          />
        </a>
        <a
          href="https://leetcode.com/u/anh-ho1_/"
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer hover:brightness-75"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1300"
        >
          <img
            src={leetcode}
            alt="LeetCode"
            className="w-9 h-9 transition duration-300"
          />
        </a>
        <Link
          to="/Contact"
          className="cursor-pointer hover:brightness-75"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1400"
        >
          <img
            src={quickMessage}
            alt="Contact"
            className="w-12 h-12 transition duration-300"
          />
        </Link>
      </div>
    </div>
  );
}
