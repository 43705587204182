import React, { useState, useEffect, useRef } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { MdSchool } from "react-icons/md";
import styled from "styled-components";
import PSU_logo from "../../image/logoImg/psuLogo.png";
import PCC_logo from "../../image/logoImg/pccLogo.webp";
const timelineElements = [
  {
    id: "001",
    title: "Portland State University",
    location: "Portland, OR",
    date: "2021 - 2024 (Graduated)",
    description:
      "Bachelor of Science in Computer Science — Major GPA: 3.93/4.0 — 🤓Summa Cum Laude🎉",
    icon: <MdSchool />,
    logo: PSU_logo,
    courses: [
      "CS 201: COMPUTER SYSTEMS PROGRAMMING",
      "CS 202: PROGRAMMING SYSTEMS",
      "CS 305: SOCIAL, ETHICAL, AND LEGAL IMPLICATIONS",
      "CS 314: ELEM SOFTWARE ENGR",
      "CS 320: PRINCIPLES OF PROG LANG",
      "CS 333: INTRO TO OPERATING SYSTEMS",
      "CS 350: ALGORITHMS & COMPLEXITY",
      "CS 410P: INTRO TO WEB DEV",
      "CS 410P: SYS ADMIN & DEVOPS",
      "CS 410P: CODE READING & REVIEW",
      "CS 430P:INTERNET, WEB, & CLOUD SYSTEM",
      "CS 464P: FRONT END WEB TECH",
      "CS 465P: FULL STACK WEB DEVELOPMENT",
      "CS 469: SOFTWARE ENGRING CAPSTONE I",
      "CS 470: SOFTWARE ENGRING CAPSTONE II",
      "CS 486: INTRO TO DATABASE MGMT SYSTEM",
    ],
  },
  {
    id: "002",
    title: "Portland Community College",
    location: "Portland, OR",
    date: "2017 - 2020 (Graduated)",
    description: "Associate of Arts Oregon Transfer Degree in Computer Science",
    icon: <MdSchool />,
    logo: PCC_logo,
    courses: [
      "CS 140U: INTRO TO UNIX",
      "CS 161: INTRO TO PROGRAMMING & PRBLM",
      "CS 162: INTRO TO COMPUTER SCIENCE",
      "CS 162: INTRO TO COMPUTER SCIENCE",
      "CS 163: DATA STRUCTURES",
      "CS 201: COMPUTER SYSTEMS PROGRAMMING",
      "CS 250: DISCRETE STRUCTURES I",
      "CS 251: DISCRETE STRUCTURES II",
      "CS 260: DATA STRUCTURES",
      "WR 227: INTRO TECHNICAL WRITING",
      "MTH 251: CALCULUS I",
      "MTH 252: CALCULUS II",
      "MTH 253: CALCULUS III",
      "MTH 261: APPLIED LINEAR ALGEBRA",
    ],
  },
];


const StyledVerticalTimelineElement = styled(VerticalTimelineElement)`
  .vertical-timeline-element-content {
    background: #f0f0f0;
    border: 1px solid #333;
    border-radius: 8px;
    max-width: 100%;
    padding: 12px;
  }
  .vertical-timeline-element-date {
    // size of the date
    max-width: 200px;
    margin-right: 10px;
  }
  .vertical-timeline-element-icon {
    background: #f9c74f;
    box-shadow: 0 0 0 2px #333 inset;
  }

  .vertical-timeline::before {
    background: #000; /* Black color for the timeline */
  }
`;

function Education() {
  const containerRef = useRef(null);
  const [isCompact, setIsCompact] = useState(false);
  const [activeElement, setActiveElement] = useState(null);
 console.log( activeElement);
  const toggleCourses = (id) => {
    setActiveElement(activeElement === id ? null : id);
  };
  console.log(toggleCourses, activeElement)
  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        setIsCompact(containerWidth < 700);
      }
    };

    handleResize(); // Call once on mount to set initial state
    window.addEventListener("resize", handleResize); // Adjust on window resize
    return () => window.removeEventListener("resize", handleResize); // Cleanup listener on unmount
  }, []);

  return (
    <>
      <style>
        {`
          .vertical-timeline {
            margin: 0 auto;
          }
          .vertical-timeline::before {
            background: #000; /* Black color for the timeline */
            width: 2px;
          }
          .vertical-timeline-element-content {
            border-color: black; /* Black border for the content cards */
          }
          .vertical-timeline-element-icon {
            box-shadow: 0 0 0 2px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
          }
        `}
      </style>
      <div
        ref={containerRef}
        className="bg-[#f0e6d6] border-2 border-[#3e2723] rounded-lg shadow-md overflow-y-auto"
      >
        <h2
          className="text-[#3e2723] text-lg font-['Courier_New', Courier, monospace]  
          mb-4 sticky top-[-5px] pt-4 bg-[#f0e6d6] z-20 pb-2
         shadow-lg shadow-stone-500/40 rounded-sm
         "
        >
          Education
        </h2>
        <VerticalTimeline layout={isCompact ? "1-column" : "2-columns"}>
          {timelineElements.map((element) => (
            <StyledVerticalTimelineElement
              key={element.id}
              date={element.date}
              icon={element.icon}
              contentArrowStyle={{
                borderRight: "10px solid #151515",
              }}
            >
              <div>
                <img
                  src={element.logo}
                  alt={element.title}
                  className="w-10 h-auto mb-1 mx-auto"
                />
                <h3 className="vertical-timeline-element-title font-Francois text-center">
                  {element.title}
                </h3>
                <h5 className="vertical-timeline-element-subtitle text-center">
                  {element.location}
                </h5>
                <p className="text-center">{element.description}</p>
                <button
                  onClick={() => toggleCourses(element.id)}
                  className="absolute top-2 right-0 text-black py-1 px-2 rounded-tl rounded-bl animate-bounce text-lg"
               
                  title={activeElement === element.id ? "Close courses" : "Show courses"}
                >
                  {activeElement === element.id ? "x" : "..."}
                  { console.log("ele id: ", element.id)}
                </button>

                <div
                  className={`${
                    activeElement === element.id ? "block" : "hidden"
                  } mt-2`}
                  id={`course-container-${element.id}`}
                >
                  <hr className=" my-2 border-t border-[#62390ccd] animate-pulse shadow-sm" />
                  {element.courses.map((course, index) => (
                    <span
                      key={index}
                      className="inline-block bg-gray-200 rounded-full px-2 text-sm font-semibold m-1"
                    >
                      {course}
                    </span>
                  ))}
                </div>
              </div>
            </StyledVerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </>
  );
}

export default Education;
