import { useState } from "react";
import axios from "axios";
import { HiOutlineMail } from "react-icons/hi";
import "../../CSS/msg.css";
import ScrollingImages from "./HorizonalScrollImages/scrollImages";
import ContentSlideThree from "../HomePage/ContentSlideThree";
const api = axios.create({
  baseURL: "http://localhost:5555/",
  headers: {
    "Content-type": "application/json",
  },
});

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [suggestion, setSuggestion] = useState("");
  // eslint-disable-next-line
  const [postResult, setPost] = useState([]);
  const [showErr, setErr] = useState("");
  const [checkSent, setCheckSent] = useState("");
  const [isAnonymous, setIsAnonymous] = useState(false); // Default to anonymous

  const clearForm = () => {
    setName("");
    setEmail("");
    setSuggestion("");
    setErr("");
    setCheckSent("");
  };

  const postMessage = async () => {
    setCheckSent(""); // Clear the "Message sent successfully!" message

    if (suggestion.trim() === "") {
      alert("Please leave some message before sending!");
      return;
    }

    if (!isAnonymous) {
      if (name.trim() === "" || email.trim() === "") {
        alert("Name and email are required for non-anonymous messages.");
        return;
      }

      const emailFilter =
        /^\w+[+.\w-]*@([\w-]+\.)*\w+[\w-]*\.([a-z]{2,4}|\d+)$/i;
      const checkEmailForm = emailFilter.test(email);
      if (!checkEmailForm) {
        alert("Please enter a valid email");
        return;
      }
    }

    await api
      .post("/letter/add", {
        name: isAnonymous ? "Anonymous" : name,
        email: isAnonymous ? "Anonymous" : email,
        message: suggestion,
      })
      .then((response) => {
        setPost(response.data);
        setCheckSent("Message sent successfully!");
      })
      .catch((error) => {
        if (error.response && error.response.status === 409)
          setErr("Oops! Something went wrong.");
        else console.error(error);
      });
  };

  const handleSubmitForm = (event) => {
    postMessage();
    event.preventDefault();
  };

  return (
    <div
      className="md:p-8 md:mx-[15vw] md:mt-20 p-6 font-Noto max-h-screen 
                  overflow-y-auto md:pb-0 pb-[18vh]"
      data-aos="zoom-out-u"
      data-aos-easing="linear"
      data-aos-duration="800"
    >
      <div className="bg-sage rounded-2xl ">
        <h1 className="contactFont font-Noto pt-5">Drop "hi" to me!</h1>
        <div className="flex justify-center m-4">
          <button
            className={`${
              isAnonymous
                ? "bg-black text-white"
                : "bg-gray-400 text-black hover:shadow-xl opacity-50"
            } font-bold py-2 px-4 rounded focus-outline-none focus-shadow-outline mr-2 w-auto`}
            onClick={() => {
              setIsAnonymous(true);
              clearForm(); // Clear the form when switching to anonymous mode
            }}
          >
            Anonymous Message
          </button>
          <button
            className={`${
              isAnonymous
                ? "bg-gray-400 text-black hover:shadow-xl  opacity-50"
                : "bg-black text-white"
            } font-bold py-2 px-4 rounded focus-outline-none focus-shadow-outline`}
            onClick={() => {
              setIsAnonymous(false);
              clearForm(); // Clear the form when switching to non-anonymous mode
            }}
          >
            Non-Anonymous Message
          </button>
        </div>
        <form className="formCustom bg-sage text-left mx-auto shadow-md rounded px-12 pb-8 mb-4">
          {!isAnonymous && (
            <>
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="name"
                >
                  <span className="text-cyan-900">Name: </span>
                </label>
                <input
                  className="form-control shadow appearance-none border 
                  rounded w-full py-2 px-3 leading-tight focus:outline-none
                  focus:shadow-outline-blue"
                  id="name"
                  type="text"
                  placeholder="Enter your name"
                  value={name}
                  onChange={(addName) => setName(addName.target.value)}
                />
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2"
                  htmlFor="email"
                >
                  <span className="text-cyan-900">Email: </span>
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <HiOutlineMail className="h-5 w-5 text-white-700" />
                  </span>
                  <input
                    className="form-control shadow appearance-none border rounded 
                    w-full py-2 pl-10 pr-3 leading-tight 
                        focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(addEmail) => setEmail(addEmail.target.value)}
                  />
                </div>
              </div>
            </>
          )}

          <div className="mb-4">
            <label
              className="block text-gray-700 font-bold mb-2"
              htmlFor="message"
            >
              <span className="text-cyan-900">Message: </span>
            </label>
            <textarea
              className="form-control shadow appearance-none border rounded w-full py-2 px-3
            leading-tight focus:outline-none focus:shadow-outline"
              id="message"
              rows={6}
              placeholder="Enter your message"
              value={suggestion}
              onChange={(addSuggestion) =>
                setSuggestion(addSuggestion.target.value)
              }
            />
          </div>
          <div className="flex items-center justify-center">
            <button
              className={`bg-black hover:bg-yellow-500 text-white hover:text-black font-bold py-2 px-4 rounded 
            focus-outline-none focus-shadow-outline hover:shadow-md`}
              onClick={handleSubmitForm}
            >
              Send Message
            </button>
          </div>
          {showErr && <p className="text-red-500">{showErr}</p>}
          {checkSent && <p className="text-green-500">{checkSent}</p>}
        </form>
      </div>

      <ContentSlideThree />
    </div>
  );
}

export default Contact;
