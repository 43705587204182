import React, { useRef, useEffect, useState } from "react";
import { gsap } from "gsap";
import DisplayDetailedProject from "../ProjectPage/DisplayDetailedProject";
import info_icon from "../../image/asserts/info_icon.gif";
import github_animated from "../../image/asserts/github_animated.gif";
import "../../CSS/global.css";

// Mock data for tech stack icons (you can replace these with actual paths)
const techStackIcons = {
  React: "path/to/react-icon.png",
  NodeJS: "path/to/nodejs-icon.png",
  // Add more technologies and their corresponding icons here
};

const ProjectCard = ({
  isSidebarExpanded,
  title,
  overview,
  techStack = [], // New prop for tech stack
  imageLink,
  githubLink,
  ...projectDetails
}) => {
  const cardRef = useRef(null);
  const infoRef = useRef(null);
  const coverRef = useRef(null);

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
    document.body.style.overflow = "hidden"; // Prevent scrolling while modal is open
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.style.overflow = "auto"; // Restore scrolling when modal is closed
  };

  useEffect(() => {
    gsap.set(infoRef.current, { yPercent: 100, visibility: "hidden" });
  }, [title, overview, imageLink, githubLink]);

  const handleMouseEnter = () => {
    gsap.to(infoRef.current, {
      yPercent: 0,
      duration: 0.3,
      visibility: "visible",
      ease: "power1.out",
    });
    gsap.to(coverRef.current, {
      opacity: 0,
      duration: 0.3,
      ease: "power1.out",
    });
  };

  const handleMouseLeave = () => {
    gsap.to(infoRef.current, {
      yPercent: 100,
      duration: 0.3,
      ease: "power3.out",
    });
    gsap.to(coverRef.current, {
      opacity: 1,
      duration: 0.3,
      ease: "power3.out",
    });
  };

  return (
    <>
      <div
        ref={cardRef}
        className={`relative m-2 mx-6 cursor-pointer rounded-2xl overflow-hidden shadow-lg border border-zinc-200
         shadow-teal-600/50 hover:shadow-teal-800/50  ${
          isModalOpen ? "active-card" : ""
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={imageLink}
          className="aspect-video w-full h-auto"
          alt="non description"
        />
        <div
          ref={coverRef}
          className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-between p-4"
        >
          <h1 className="text-xl font-bold font-Noto text-white animate-pulse ">{title}</h1>
          <div className="flex flex-wrap">
            {techStack &&
              techStack
                .sort((a, b) => a.localeCompare(b))
                .map((tech) =>
                techStackIcons[tech] ? (
                  <img
                    key={tech}
                    src={techStackIcons[tech]}
                    alt={`${tech} Icon`}
                    className="h-6 w-6 mb-1 mr-2"
                  />
                ) : (
                  <div
                    key={tech}
                        className="h-6 w-auto mb-1 px-2 py-1 mr-1 border border-gray-300
                                   text-white text-xs rounded"
                  >
                    {tech}
                  </div>
                )
              )}
          </div>
        </div>
        <div
          ref={infoRef}
          className="absolute bottom-0 w-full transition-transform duration-300 ease-in-out rounded-t-md"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.95)" }}
          onClick={openModal}
        >
          <div className="p-2">
            <p className="text-sm text-center ">{overview}</p>
            <div className="flex justify-center mt-2">
              {githubLink && (
                <a
                  href={githubLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 text-sm flex items-center space-x-1"
                >
                  <img
                    src={github_animated}
                    className="h-8 w-8"
                    alt="GitHub Icon"
                  />
                </a>
              )}
              <button onClick={openModal} className="info-button">
                <img src={info_icon} className="h-7 w-7" alt="Info Icon" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <DisplayDetailedProject
          isOpen={isModalOpen}
          onClose={closeModal}
          title={title}
          overview={overview}
          techStack={techStack}
          {...projectDetails}
          isSidebarExpanded={isSidebarExpanded}
        />
      )}
    </>
  );
};

export default ProjectCard;
