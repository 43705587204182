

// TimeZoneAPI.js
import React, { useState, useEffect } from 'react';

const TimeZoneAPI = ({ timezone, isSidebarExpanded }) => {
    const [dateTime, setDateTime] = useState(new Date());
    const [flash, setFlash] = useState(true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDateTime(new Date());
        }, 1000);

        // Flashing effect for the colon every 500ms
        const flashInterval = setInterval(() => {
            setFlash(f => !f);
        }, 500);

        return () => {
            clearInterval(intervalId);
            clearInterval(flashInterval);
        };
    }, []);

    const formattedDateTime = () => {
        const shortDate = {
            timeZone: timezone,
            weekday: 'short',
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        };

        const optionsTime = {
            timeZone: timezone,
            hour: '2-digit',
            minute: 'numeric',
            // second: 'numeric',
            hour12: true,
        };

        const shortFomartDate = new Intl.DateTimeFormat('en-US', shortDate).format(dateTime);
        const formatTime = new Intl.DateTimeFormat('en-US', optionsTime).format(dateTime);

        return {
            shortDate: shortFomartDate,
            time: formatTime,
        };
    };
    // Split the time string to insert the flashing colon
    const timeParts = formattedDateTime().time.split(':');

    const flashingSecond = (
        <>
            {timeParts[0]}
            <span className={`${flash ? 'opacity-100' : 'opacity-0'}`}>:</span>
            {timeParts[1]}
        </>
    );

    return (
        <div
            className={`text-white absolute transition-all duration-300 mt-1 font-Sung ${isSidebarExpanded ? 'p-2 bottom-[140px]' : 'bottom-[100px]'}`}
            style={{
                textShadow: '1px 1px 2px #9DCBBA, 0 0 1em #6D5959, 0 0 0.2em #A6F9FA',
            }}
        >
            <p className={` ${isSidebarExpanded ? 'text-2xl' : 'text-sm'}`}>{flashingSecond}</p>
            <p className={` ${isSidebarExpanded ? 'text-xl' : 'text-xs'}`}>{formattedDateTime().shortDate}</p>
        </div >
    );
};

export default TimeZoneAPI;
