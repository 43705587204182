import React, { useEffect } from "react";
import { preLoadAnimation } from "./loadingLandingPage";
import loadingIcon from "../../image/logo_AH2.webp";
import "../../CSS/preloader.scss";

const PreLoader = () => {
  useEffect(() => {
    preLoadAnimation();
  }, []);

  return (
    <div className="preloader">
      <div className="texts-container">
        <img src={loadingIcon} alt="Loading Icon" className="loading-icon" />
        <span>Anh Ho,</span>
        <span>A FullStack Developer,</span>
        <span>Team Player!</span>
      </div>
    </div>
  );
};

export default PreLoader;
