
import {
    FaReact,
    FaNodeJs,
    FaVuejs,
    FaAws,
    FaDocker,
    FaPython,
    FaJava,
    FaGitAlt,
    FaLinux,
    FaNetworkWired,
    FaAdobe,
    FaBootstrap,
} from "react-icons/fa";
import {
    SiJavascript,
    SiTypescript,
    SiHtml5,
    SiCss3,
    SiKubernetes,
    SiGooglecloud,
    SiJira,
    SiTailwindcss,
    SiBootstrap,
    SiRedux,
    SiJest,
    SiGraphql,
    SiMysql,
    SiPostgresql,
    SiFlask,
    SiJquery,
    SiAdobephotoshop,
    SiAdobepremierepro,
    SiAdobeillustrator,
    SiMicrosoftoffice,
    SiCitrix,
    SiCplusplus,
    SiFirewalla,
    SiMongodb,
    SiMongoose,
    SiNodedotjs,
    SiVite,
    SiGit,
    SiAmazonaws,
    SiLatex,
    SiC,
    SiLinux
    
} from "react-icons/si";

const Data_techStack = [
    // Full Stack - Frontend
    {
        name: "HTML5",
        icon: <SiHtml5 />,
        category: "Full Stack",
        subcategory: "Frontend",
    },
    {
        name: "CSS3",
        icon: <SiCss3 />,
        category: "Full Stack",
        subcategory: "Frontend",
    },
    {
        name: "JavaScript",
        icon: <SiJavascript />,
        category: "Full Stack",
        subcategory: "Frontend",
    },
    {
        name: "TypeScript",
        icon: <SiTypescript />,
        category: "Full Stack",
        subcategory: "Frontend",
    },
    {
        name: "Bootstrap",
        icon: <SiBootstrap />,
        category: "Full Stack",
        subcategory: "Frontend",
    },
    {
        name: "TailWindCSS",
        icon: <SiTailwindcss />,
        category: "Full Stack",
        subcategory: "Frontend",
    },
    {
        name: "jQuery",
        icon: <SiJquery />,
        category: "Full Stack",
        subcategory: "Frontend",
    },
    // Full Stack - Backend
    {
        name: "Python",
        icon: <FaPython />,
        category: "Full Stack",
        subcategory: "Backend",
    },
    {
        name: "Java",
        icon: <FaJava />,
        category: "Full Stack",
        subcategory: "Backend",
    },
    {
        name: "C++",
        icon: <SiCplusplus />,
        category: "Full Stack",
        subcategory: "Backend",
    },
    {
        name: "Node.js",
        icon: <SiNodedotjs />,
        category: "Full Stack",
        subcategory: "Backend",
    },
    {
        name: "C",
        icon: <SiC />,
        category: "Full Stack",
        subcategory: "Backend",
    },



    // Database
    {
        name: "SQL Databases",
        icon: <SiMysql />,
        category: "Full Stack",
        subcategory: "Database",
    },
    {
        name: "PostgreSQL",
        icon: <SiPostgresql />,
        category: "Full Stack",
        subcategory: "Database",
    },
    {
        name: "MongoDB",
        icon: <SiMongodb />,
        category: "Full Stack",
        subcategory: "Database",
    },
    {
        name: "Mongoose",
        icon: <SiMongoose />,
        category: "Full Stack",
        subcategory: "Database",
    },
    // Full Stack - Frameworks & Libraries
    {
        name: "ReactJS",
        icon: <FaReact />,
        category: "Full Stack",
        subcategory: "Frameworks & Libraries",
    },
    {
        name: "Vue.js",
        icon: <FaVuejs />,
        category: "Full Stack",
        subcategory: "Frameworks & Libraries",
    },
    {
        name: "Redux",
        icon: <SiRedux />,
        category: "Full Stack",
        subcategory: "Frameworks & Libraries",
    },
    {
        name: "Jest",
        icon: <SiJest />,
        category: "Full Stack",
        subcategory: "Frameworks & Libraries",
    },
    {
        name: "Material-UI",
        icon: <SiBootstrap />,
        category: "Full Stack",
        subcategory: "Frameworks & Libraries",
    },
    {
        name: "Flask",
        icon: <SiFlask />,
        category: "Full Stack",
        subcategory: "Frameworks & Libraries",
    },
    {
        name: "GraphQL",
        icon: <SiGraphql />,
        category: "Full Stack",
        subcategory: "Frameworks & Libraries",
    },
    {
        name: "Vite",
        icon: <SiVite />,
        category: "Full Stack",
        subcategory: "Frameworks & Libraries",
    },
    {
        name: "GSAP",
        icon: null,
        category: "Full Stack",
        subcategory: "Frameworks & Libraries",
    },




    {
        name: "VSCode",
        icon: null,
        category: "Full Stack",
        subcategory: "Tools",
    },
    {
        name: "Git",
        icon: <SiGit />,
        category: "Full Stack",
        subcategory: "Tools",
    },
    {
        name: "Google Cloud Computing",
        icon: <SiGooglecloud />,
        category: "Full Stack",
        subcategory: "Tools",
    },

    {
        name: "Amazon Web Services",
        icon: <SiAmazonaws />,
        category: "Full Stack",
        subcategory: "Tools",
    },
    {
        name: "Latex",
        icon: <SiLatex />,
        category: "Full Stack",
        subcategory: "Tools",
    },
    {
        name: "Unix/Linux",
        icon: <SiLinux />,
        category: "Full Stack",
        subcategory: "Tools",
    },



    // =============== IT Support Tools =========================== //
    { name: "Cisco ", icon: <FaNetworkWired />, category: "IT Support" },
    {
        name: "Active Directory",
        icon: <FaNetworkWired />,
        category: "IT Support",
    },
    {
        name: "Linux/Unix",
        icon: <FaLinux />,
        category: "IT Support",
    },
    {
        name: "Service Now",
        icon: null,
        category: "IT Support",
    },
    {
        name: "MS Suite Application",
        icon: <SiMicrosoftoffice />,
        category: "IT Support",
    },
    {
        name: "RDP",
        icon: null,
        category: "IT Support",
    },
    {
        name: "SCCM",
        icon: null,
        category: "IT Support",
    },
    {
        name: "TCP/IP/DHCP",
        icon: null,
        category: "IT Support",
    },
    {
        name: "Firewall",
        icon: <SiFirewalla />,
        category: "IT Support",
    },
    {
        name: "Snort rules",
        icon: null,
        category: "IT Support",
    },
    {
        name: "Bomgar",
        icon: null,
        category: "IT Support",
    },
    {
        name: "Jira",
        icon: <SiJira />,
        category: "IT Support",
    },

    {
        name: "Citrix",
        icon: <SiCitrix />,
        category: "IT Support",
    },
    // Editing/Design Tools
    {
        name: "Adobe Photoshop",
        icon: <SiAdobephotoshop />,
        category: "Editing/Design",
    },
    {
        name: "Adobe Premiere Pro",
        icon: <SiAdobepremierepro />,
        category: "Editing/Design",
    },
    {
        name: "Adobe Illustrator",
        icon: <SiAdobeillustrator />,
        category: "Editing/Design",
    },
    {
        name: "Adobe After Effects",
        icon: null,
        category: "Editing/Design",
    }, // Placeholder icon
];

export default Data_techStack;