import React from "react";
import ReactDOM from "react-dom";
import TypeWriter from "../../components/utils/typeWriter";
import "../../CSS/global.css";

const DisplayDetailedProject = ({
  isSidebarExpanded,
  isOpen,
  onClose,
  title,
  overview,
  techStack,
  duration,
  responsibilities,
  achievements,
  collaboration,
}) => {
  // Using an array to store the information can indeed help reduce repetitive code
  const projectInfo = [
    { label: "Tech Stack", value: techStack.join(", ") },
    {
      label: "Duration",
      value: `${duration.startDate} to ${duration.endDate}`,
    },
    { label: "Responsibilities", value: responsibilities },
    { label: "Achievements", value: achievements },
    { label: "Collaboration", value: collaboration },
  ];

  // project info displays on each row
  const renderTableRows = () => {
    return projectInfo.map((row, index) => (
      <tr
        key={index}
        className="border-b border-gray-400 flex flex-col sm:flex-row"
      >
        <td className="p-1 md:p-4 font-bold font-Noto min-w-[140px]">
          {row.label}:
        </td>
        <td className="px-2 pb-2 md:p-4">
          <TypeWriter sourceText={row.value} delay={7} />
        </td>
      </tr>
    ));
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    // Overlay
    <div
      className={`transition-all duration-300 fixed inset-0 bg-stone-800 bg-opacity-75 overflow-y-auto h-full w-full z-50 backdrop-blur-sm shadow-lg ${
        isSidebarExpanded ? "md:ms-40" : "md:ms-20"
      }`}
      onClick={onClose}
    >
      {/* Modal content */}
      <div
        className={`relative top-10 mx-auto p-0 overflow-y-auto max-h-[800px] md:w-full border
                w-100 max-w-screen-sm shadow-lg rounded-md bg-white`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close button in modal */}
        <div className="flex justify-between items-center pb-2 sticky top-0 bg-white z-10 border-2 rounded-md shadow-md">
          <p className="text-xl md:text-2xl font-bold font-Noto pl-4 pt-2">
            {title}
          </p>
          <div className="cursor-pointer m-4" onClick={onClose}>
            <svg
              className="fill-current text-black hover:text-blue-600 flash-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 18 18"
            >
              <path d="M15 3.6L14.4 3 9 8.4 3.6 3 3 3.6 8.4 9 3 14.4l.6.6L9 9.6l5.4 5.4.6-.6L9.6 9z" />
            </svg>
          </div>
        </div>

        {/* Modal body contains project Info*/}
        <div className="px-4 pb-8 gap-2 pt-2">
          <p className="px-1 pb-3">
            {/* overview in modal */}
            <TypeWriter sourceText={overview} delay={100} />
          </p>
          <table className="border-collapse border border-gray-400 ">
            {/* details in modal */}
            <tbody className="align-top">{renderTableRows()}</tbody>
          </table>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default DisplayDetailedProject;
