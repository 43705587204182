// slideContentData.js

const slideContentData = {
  slideOne: {
    tittle: `Hello there,`,
    mainContent:
        `I'm Anh, a tech enthusiast and problem-solving ninja. By day, I'm a coding maestro in C/C++ and Java. But by night, I morph into a cloud-wrangler, taming the wilds of Google Cloud Platform and Amazon Web Services.`,

    expandedContent:
        `I'm on a quest for my next tech adventure, armed with expertise in frontend and backend development, and a toolbox filled with JavaScript, TypeScript, Node.js, and React.js.
        
        Looking for a dynamic team player ready to tackle any challenge and bring innovation to your organization? Let's connect and build something extraordinary
        together!
    
        // Ready to make magic happen?
        `,
  },

  slideTwo: {
    tittle: `Life Beyond the Code,`,
    mainContent:
        `Beyond the code, I find immense joy in the camaraderie of teamwork, especially in sports. Collaborating with my team, I dive into the world of basketball, ping pong, and table tennis, striving together to achieve joys and even victory in every game.`,
    expandedContent:
        `I relish the opportunity to connect with others. Whether it's discussing innovative design concepts, dissecting the latest sports games, sharing travel experiences, or indulging in culinary delights, I'm always eager to engage in lively conversations.

        Last but certainly not least, I'm a devoted animal lover. My furry companions, both cats and dogs, provide me with comfort and companionship during the quieter moments of the day, helping me unwind and find solace in their presence.
        
        // Excited to blend my passion for technology with my vibrant lifestyle!
        `,
  },
};

export default slideContentData;
