import React from "react";
import styled, { keyframes } from "styled-components";
import Project_Bg from "../../image/projectTab/background_galaxy.webp";

const spin = keyframes`
  from {
    transform: rotate(0deg) scale(2.25);
  }
  to {
    transform: rotate(360deg) scale(3);
  }
`;

const BackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url(${Project_Bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transform: scale(2);
  transform-origin: center;
  animation: ${spin} ${(props) => props.$duration}s linear infinite;
`;

const ContentWrapper = styled.div`
  z-index: 1;
`;

const RotatingBackground = ({ duration, children }) => {
  return (
    <BackgroundWrapper>
      <BackgroundImage $duration={duration} />
      <ContentWrapper>{children}</ContentWrapper>
    </BackgroundWrapper>
  );
};

export default RotatingBackground;
