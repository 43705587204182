// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import ProjectsList from "./ProjectsList";
import Pagination from "./Pagination";
import ProjectTabs from "./ProjectTabs";
import projectData from "../ProjectPage/ProjectData.json";
import SearchingBar from "./SearchingBar";
import "../../CSS/projectMain.css";
import closeout from "../../image/asserts/closeout-btn.png";

const ProjectMain = ({ isSidebarExpanded }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("Title");
  const [searchInput, setSearchInput] = useState("");
  const [currentProjectType, setCurrentProjectType] = useState("present");
  const [isSearchActive, setIsSearchActive] = useState(false); // New state for search active

  const itemsPerPage = 6; // Adjust based on your design requirements

  const sortProjectsByEndDate = (projects) => {
    return projects.sort((a, b) => {
      if (a.duration.endDate === "Present") return -1;
      if (b.duration.endDate === "Present") return 1;
      return new Date(b.duration.endDate) - new Date(a.duration.endDate);
    });
  };

  const presentProjects = sortProjectsByEndDate(
    projectData.projects.filter(
      (project) => project.duration.endDate === "Present"
    )
  );
  const oldProjects = sortProjectsByEndDate(
    projectData.projects.filter(
      (project) => project.duration.endDate !== "Present"
    )
  );

  const allProjects = [...presentProjects, ...oldProjects];
  const getProjectsData = (type) =>
    type === "present" ? presentProjects : oldProjects;

  const currentData = getProjectsData(currentProjectType);

  const pageCount = Math.ceil(
    searchResults.length > 0
      ? searchResults.length / itemsPerPage
      : currentData.length / itemsPerPage
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems =
    searchResults.length > 0
      ? searchResults.slice(indexOfFirstItem, indexOfLastItem)
      : currentData.slice(indexOfFirstItem, indexOfLastItem);

  const goToNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < pageCount ? prevPage + 1 : prevPage
    );
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  /**
   * This function handles the search functionality.
   * @param category - The category to search in.
   * @param input - The input to search for.
   */
  const handleSearch = (category, input) => {
    setSelectedCategory(category);
    setSearchInput(input);

    let results = [];

    // If there is no input, show the original list of projects.
    if (!input) {
      results = currentData;
      setIsSearchActive(false); // No input, show tabs
    } else {
      // Otherwise, filter the projects based on the input and category.
      results = allProjects.filter((project) => {
        // If no category is selected, search through all project values.
        if (!category) {
          return Object.values(project).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(input.toLowerCase())
          );
        }
        // Otherwise, search through the selected category.
        return (
          typeof project[category] === "string" &&
          project[category].toLowerCase().includes(input.toLowerCase())
        );
      });
      setIsSearchActive(true); // Input exists, show search results
    }
    setCurrentPage(1); // Reset to the first page of search results
    // Set the search results to either the filtered projects or a single project with noResult: true
    setSearchResults(results.length > 0 ? results : [{ noResult: true }]);
  };

  
  useEffect(() => {
    setSearchResults([]);
    setSearchInput("");
    setIsSearchActive(false);
    setCurrentPage(1);
  }, [currentProjectType]);
  return (
    <div className="flex flex-col h-screen pb-10 md:pb-0 bg-cover bg-center lg:px-32">
      {/* Present Projects and Previous Projects */}
      {!isSearchActive && (
        <ProjectTabs
          currentProjectType={currentProjectType}
          setCurrentProjectType={setCurrentProjectType}
        />
      )}
      {/* Search Results */}
      {isSearchActive && (
        <div className="text-center text-2xl font-bold my-4 pt-4 animate-bounce flex justify-center  space-x-1">
          <div className="vintage-button flex items-center justify-center">
            Search Results
          </div>

          <button
            className="vintage-button bg-[#3e2723] hover:bg-[#6b4e3d] flex items-center justify-center"
            onClick={() => {
              setIsSearchActive(false);
              setSearchInput("");
              setCurrentProjectType("present");
              setSearchResults([]);
              setCurrentPage(1);
            }}
          >
            <img className="h-8 w-8 p-0" src={closeout} alt="Close Icon" />
          </button>
        </div>
      )}

      <div className="overflow-y-auto pb-[20vh] md:pb-0">
        <ProjectsList
          projects={currentItems}
          isSidebarExpanded={isSidebarExpanded}
        />
      </div>
      <div className="mt-auto w-full flex flex-col md:flex-row justify-center pt-0 pb-10 md:pb-0 md:mb-9 border-t md:border border-stone-700 shadow-md shadow-stone-600/50 backdrop-filter backdrop-blur-sm fixed bottom-8 md:relative md:top-0 md:bottom-auto">
        <div className="border-gray-200 p-2 m-auto flex">
          {/* This is the searching bar. It is rendered only when the search is active.
              The options in the searching bar are the keys of the first project in the projectData.projects array. 
              When the user types something in the searching bar, the handleSearch function is called with the typed text as its argument.
              The handleSearch function updates the search results based on the user's input and updates the state variables accordingly.
           */}
          <SearchingBar
            // The options in the searching bar are the keys of the first project in the projectData.projects array.
            // This is why the options are set to Object.keys(projectData.projects[0])
            options={Object.keys(projectData.projects[0])}
            onSearch={handleSearch}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
            className="w-full md:w-auto"
          />
        </div>
        <Pagination
          currentPage={currentPage}
          pageCount={pageCount}
          goToPreviousPage={goToPreviousPage}
          goToNextPage={goToNextPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default ProjectMain;
